<template>
  <v-btn
    color="error"
    icon
    @click="redirectToProFemina"
  >
    <v-icon
      :large="$vuetify.breakpoint.smAndUp"
      color="close"
    >
      {{ `mdi-${iconText}` }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';
import { redirectProfeminaOrigin } from '@/utils/redirect';

export default {
  name: 'CloseButton',
  computed: {
    ...mapGetters('testMicro', [
      'id',
    ]),
    ...mapGetters('app', [
      'resultView',
    ]),
    iconText() {
      return this.resultView ? 'home' : 'close-circle-outline';
    },
  },
  methods: {
    redirectToProFemina() {
      const { localeCountry } = this.$route.params;
      redirectProfeminaOrigin(localeCountry);
    },
  },
};
</script>
